import request from '@/router/axios';
import {businessUrl} from "@/config/env"


//获取品种价格数据集合
export const getProductPriceList = (startTime,endTime,productId,isBase) => {
    return request({
        url: businessUrl + "productPriceRecord/selectPage",
        method: "post",
        data: {
            startTime,
            endTime,
            productId,
            isBase
        }
    })
}








