<template>
    <div class="productPrice">
        <div class="top-heaer">
            <el-row>
                <el-col :span="24">
                    <div class="grid-content bg-purple-dark d-flex"
                         style="justify-content: space-between;display: flex;">
                        <div style="display: flex;margin-bottom: 20px;margin-left: 10px;">
                            <div class="d-flex">
                                <div>
                                    <el-date-picker v-model="selectForm.startTime"
                                                    value-format="yyyy-MM-dd"
                                                    type="date"
                                                    :clearable="false"
                                                    placeholder="选择开始日期" style="width:100%;">
                                    </el-date-picker>
                                </div>
                            </div>
                            <div class="d-flex" style="margin-left: 10px;">
                                <div>
                                    <el-date-picker v-model="selectForm.endTime"
                                                    value-format="yyyy-MM-dd"
                                                    type="date"
                                                    :clearable="false"
                                                    placeholder="选择结束日期" style="width:100%;">
                                    </el-date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex" style="display: flex;">
                            <div class="d-flex" style="margin-left: 10px;">
                                <div>
                                    <el-select clearable v-model="selectForm.isBase"
                                               :placeholder="$t('IF STANDARD PRODUCT PRICE')"
                                               style="width:180px !important;marginRight: 5px;">
                                        <el-option :label="$t('YES')" value="0">{{ $t('YES') }}</el-option>
                                        <el-option :label="$t('NO')" value="1">{{ $t('NO') }}</el-option>
                                    </el-select>
                                </div>
                            </div>
                            <el-badge>
                                <img style="padding-top: 4px; padding-right: 15px; padding-left: 15px; height: 1.7rem; width: 1.7rem;"
                                     :title="$t('REFRESH')" @click="batch" src="/img/Bnt/refresh.png"/>
                            </el-badge>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="table" style="height: 550px">
            <el-table
                    :data="handoverForm.tempList"
                    border
                    ref="table"
                    height="100%"
                    style="width: 100%">
                <el-table-column
                        prop="productName"
                        label="品种"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="createTime"
                        label="变更时间"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="beforeChange"
                        label="变更前价格"
                        align="center"
                >
                </el-table-column>
                <el-table-column
                        prop="afterChange"
                        label="变更后价格"
                        align="center"
                >
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import {getProductPriceList} from '@/api/stealth/productPrice.js'

    export default {
        name: 'productPrice',
        data() {
            return {
                selectForm: {
                    startTime: '',
                    endTime: '',
                    productId: '',
                    isBase: '',
                },
                handoverForm: {
                    tempList: [],
                    productId: '',
                    productName: '',
                    createTime: '',
                    beforeChange: '',
                    afterChange: '',
                    isBase: '',
                },

            }
        },
        created() {
            this.selectForm.startTime = this.funDate(-30)
            this.selectForm.endTime = this.funDate(0)
            this.selectForm.productId=this.$route.query.productId;
            getProductPriceList(this.selectForm.startTime + ' 00:00:00', this.selectForm.endTime + ' 00:00:00', this.selectForm.productId, this.selectForm.isBase).then(res => {
                let template = this.list = res.data.data
                this.handoverForm.tempList = []
                for (let templateKey in template) {
                    let obj1 = {
                        'id': template[templateKey].id,
                        'productId': template[templateKey].productId,
                        'productName': template[templateKey].productName,
                        'createTime': template[templateKey].createTime,
                        'beforeChange': template[templateKey].beforeChange,
                        'afterChange': template[templateKey].afterChange,
                        'isBase': template[templateKey].isBase,
                    }
                    this.handoverForm.tempList.push(obj1)
                }
            })
        },
        methods: {
            funDate(aa) {
                let date1 = new Date()
                let date2 = new Date(date1)
                date2.setDate(date1.getDate() + aa)
                let time2 = date2.getFullYear() + '-' + (date2.getMonth() + 1) + '-' + date2.getDate()
                return time2
            },
            batch() {
                getProductPriceList(this.selectForm.startTime + ' 00:00:00', this.selectForm.endTime + ' 00:00:00', this.selectForm.productId, this.selectForm.isBase).then(res => {
                    let template = this.list = res.data.data
                    this.handoverForm.tempList = []
                    for (let templateKey in template) {
                        let obj1 = {
                            'id': template[templateKey].id,
                            'productId': template[templateKey].productId,
                            'productName': template[templateKey].productName,
                            'createTime': template[templateKey].createTime,
                            'beforeChange': template[templateKey].beforeChange,
                            'afterChange': template[templateKey].afterChange,
                            'isBase': template[templateKey].isBase,
                        }
                        this.handoverForm.tempList.push(obj1)
                    }
                })

            },
            standAlone() {
                this.handoverForm.tempList = []
            }

        }
    }
</script>

<style scoped lang="scss">
    /deep/ .el-card__header {
        color: #FFFFFF;
        background-color: #aaa;
    }

    .blue {
        background-color: #1f94f4;
    }

    .yieldManagement {
        padding: 20px;
        background: rgba(245, 246, 248, 1);

        .border-none {
            border: none;
        }

        .bg7caff5 {
            background: #7CAFF5;
        }

        .top-heaer {
            background: #fff;
            padding: 10px;
            border-radius: 5px;
        }

        .lable {
            margin-right: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .d-flex {
            display: flex;
        }

        .select-box {
            padding-left: 30px;
            height: 70px;
            border-bottom: 1px solid rgba(151, 151, 151, 1);
            margin-bottom: 30px;

            .btn-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: 30px;

                .btn {
                    background: none;
                    color: rgba(0, 0, 0, .6);
                    border: none;
                }

                .btn-ac {
                    background: rgba(90, 155, 248, 1);
                    color: #fff;
                }
            }
        }

        .table {
            margin-top: 35px;

            /deep/ th {
                background: rgba(224, 226, 228, 1) !important;
                color: #000 !important;
            }
        }
    }
</style>
